.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentWrap{
  flex: 1;
}

.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 35px;
  right: 50px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1200;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  color: #ffffff;
  background-color: #f50057;
  border-radius: 4px;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 0.5;
  }
}